import { forwardRef } from 'react'
import type { IconProps } from '~/lib/icons'
import { getEnhancedIconProps } from '~/lib/icons'

export default forwardRef<SVGSVGElement, IconProps>(function InfoOutlinedIcon(props, ref) {
	return (
		<svg ref={ref} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...getEnhancedIconProps(props)}>
			<circle cx="8" cy="8" r="7.5" stroke="currentColor" />
			<path fillRule="evenodd" clipRule="evenodd" d="M7.5 6.5L8.5 6.5V12H7.5L7.5 6.5Z" fill="currentColor" />
			<path fillRule="evenodd" clipRule="evenodd" d="M7.5 4L8.5 4V5H7.5L7.5 4Z" fill="currentColor" />
		</svg>
	)
})
